import React from "react";
import data from "../../assets/promotion/animation.json";

export default function MovingSlider() {
  const onClick = () => (window.location.href = data.url);

  return (
    <div
      className="cs_moving_text_wrap cs_style_1 cs_fs_37 cs_semibold cs_white_color cs_accent_bg"
      onClick={onClick}
    >
      <div className="cs_moving_text_in">
        <div className="cs_moving_text">
          <svg
            width={30}
            height={30}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 13.468C0.0859599 13.4758 0.17192 13.4836 0.25788 13.4836C3.86038 13.4836 7.46288 13.4836 11.0732 13.4836C11.1513 13.4836 11.2295 13.4836 11.292 13.4836C8.61943 10.8103 5.94686 8.13705 3.2821 5.4716C4.04011 4.72121 4.73561 4.04117 5.44673 3.33768C8.08023 5.97186 10.7528 8.64513 13.4957 11.3887C13.4957 7.53517 13.4957 3.76759 13.4957 0C14.5116 0 15.4962 0 16.5043 0C16.5043 3.7754 16.5043 7.52736 16.5043 11.2715C19.1769 8.59823 21.8494 5.92496 24.4986 3.27514C25.2566 4.03335 25.9443 4.72121 26.6476 5.4247C24.0141 8.05889 21.3415 10.7322 18.6689 13.4054C18.6767 13.4289 18.6924 13.4523 18.7002 13.4758C22.459 13.4758 26.2256 13.4758 30 13.4758C30 14.4919 30 15.4768 30 16.493C26.2256 16.493 22.4668 16.493 18.6767 16.493C21.3806 19.1975 24.0453 21.863 26.7101 24.5284C25.9755 25.2475 25.2878 25.9276 24.5845 26.6232C21.951 23.9891 19.2785 21.3158 16.559 18.5878C16.5434 18.7363 16.5277 18.7989 16.5277 18.8614C16.5277 22.5039 16.5277 26.1464 16.5277 29.7968C16.5277 29.8671 16.5434 29.9297 16.5434 30C15.5275 30 14.5116 30 13.4957 30C13.5035 29.914 13.5113 29.828 13.5113 29.7421C13.5113 26.1386 13.5113 22.5352 13.5113 18.9317C13.5113 18.8536 13.4957 18.7676 13.4879 18.5956C10.745 21.3236 8.07242 23.9969 5.43892 26.6311C4.73561 25.9354 4.04011 25.2553 3.27429 24.505C5.93905 21.8395 8.6038 19.174 11.2764 16.5008C11.2217 16.5008 11.1435 16.5008 11.0654 16.5008C7.46288 16.5008 3.86038 16.5008 0.25788 16.5008C0.17192 16.5008 0.0859599 16.5164 0 16.5164C0 15.5003 0 14.4841 0 13.468Z"
              fill="#070707"
            />
          </svg>
          {data.text}
          {/* <svg
            width={30}
            height={30}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 13.468C0.0859599 13.4758 0.17192 13.4836 0.25788 13.4836C3.86038 13.4836 7.46288 13.4836 11.0732 13.4836C11.1513 13.4836 11.2295 13.4836 11.292 13.4836C8.61943 10.8103 5.94686 8.13705 3.2821 5.4716C4.04011 4.72121 4.73561 4.04117 5.44673 3.33768C8.08023 5.97186 10.7528 8.64513 13.4957 11.3887C13.4957 7.53517 13.4957 3.76759 13.4957 0C14.5116 0 15.4962 0 16.5043 0C16.5043 3.7754 16.5043 7.52736 16.5043 11.2715C19.1769 8.59823 21.8494 5.92496 24.4986 3.27514C25.2566 4.03335 25.9443 4.72121 26.6476 5.4247C24.0141 8.05889 21.3415 10.7322 18.6689 13.4054C18.6767 13.4289 18.6924 13.4523 18.7002 13.4758C22.459 13.4758 26.2256 13.4758 30 13.4758C30 14.4919 30 15.4768 30 16.493C26.2256 16.493 22.4668 16.493 18.6767 16.493C21.3806 19.1975 24.0453 21.863 26.7101 24.5284C25.9755 25.2475 25.2878 25.9276 24.5845 26.6232C21.951 23.9891 19.2785 21.3158 16.559 18.5878C16.5434 18.7363 16.5277 18.7989 16.5277 18.8614C16.5277 22.5039 16.5277 26.1464 16.5277 29.7968C16.5277 29.8671 16.5434 29.9297 16.5434 30C15.5275 30 14.5116 30 13.4957 30C13.5035 29.914 13.5113 29.828 13.5113 29.7421C13.5113 26.1386 13.5113 22.5352 13.5113 18.9317C13.5113 18.8536 13.4957 18.7676 13.4879 18.5956C10.745 21.3236 8.07242 23.9969 5.43892 26.6311C4.73561 25.9354 4.04011 25.2553 3.27429 24.505C5.93905 21.8395 8.6038 19.174 11.2764 16.5008C11.2217 16.5008 11.1435 16.5008 11.0654 16.5008C7.46288 16.5008 3.86038 16.5008 0.25788 16.5008C0.17192 16.5008 0.0859599 16.5164 0 16.5164C0 15.5003 0 14.4841 0 13.468Z"
              fill="#070707"
            />
          </svg>
          {data.text}
          <svg
            width={30}
            height={30}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 13.468C0.0859599 13.4758 0.17192 13.4836 0.25788 13.4836C3.86038 13.4836 7.46288 13.4836 11.0732 13.4836C11.1513 13.4836 11.2295 13.4836 11.292 13.4836C8.61943 10.8103 5.94686 8.13705 3.2821 5.4716C4.04011 4.72121 4.73561 4.04117 5.44673 3.33768C8.08023 5.97186 10.7528 8.64513 13.4957 11.3887C13.4957 7.53517 13.4957 3.76759 13.4957 0C14.5116 0 15.4962 0 16.5043 0C16.5043 3.7754 16.5043 7.52736 16.5043 11.2715C19.1769 8.59823 21.8494 5.92496 24.4986 3.27514C25.2566 4.03335 25.9443 4.72121 26.6476 5.4247C24.0141 8.05889 21.3415 10.7322 18.6689 13.4054C18.6767 13.4289 18.6924 13.4523 18.7002 13.4758C22.459 13.4758 26.2256 13.4758 30 13.4758C30 14.4919 30 15.4768 30 16.493C26.2256 16.493 22.4668 16.493 18.6767 16.493C21.3806 19.1975 24.0453 21.863 26.7101 24.5284C25.9755 25.2475 25.2878 25.9276 24.5845 26.6232C21.951 23.9891 19.2785 21.3158 16.559 18.5878C16.5434 18.7363 16.5277 18.7989 16.5277 18.8614C16.5277 22.5039 16.5277 26.1464 16.5277 29.7968C16.5277 29.8671 16.5434 29.9297 16.5434 30C15.5275 30 14.5116 30 13.4957 30C13.5035 29.914 13.5113 29.828 13.5113 29.7421C13.5113 26.1386 13.5113 22.5352 13.5113 18.9317C13.5113 18.8536 13.4957 18.7676 13.4879 18.5956C10.745 21.3236 8.07242 23.9969 5.43892 26.6311C4.73561 25.9354 4.04011 25.2553 3.27429 24.505C5.93905 21.8395 8.6038 19.174 11.2764 16.5008C11.2217 16.5008 11.1435 16.5008 11.0654 16.5008C7.46288 16.5008 3.86038 16.5008 0.25788 16.5008C0.17192 16.5008 0.0859599 16.5164 0 16.5164C0 15.5003 0 14.4841 0 13.468Z"
              fill="#070707"
            />
          </svg>
          {data.text}
          <svg
            width={30}
            height={30}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 13.468C0.0859599 13.4758 0.17192 13.4836 0.25788 13.4836C3.86038 13.4836 7.46288 13.4836 11.0732 13.4836C11.1513 13.4836 11.2295 13.4836 11.292 13.4836C8.61943 10.8103 5.94686 8.13705 3.2821 5.4716C4.04011 4.72121 4.73561 4.04117 5.44673 3.33768C8.08023 5.97186 10.7528 8.64513 13.4957 11.3887C13.4957 7.53517 13.4957 3.76759 13.4957 0C14.5116 0 15.4962 0 16.5043 0C16.5043 3.7754 16.5043 7.52736 16.5043 11.2715C19.1769 8.59823 21.8494 5.92496 24.4986 3.27514C25.2566 4.03335 25.9443 4.72121 26.6476 5.4247C24.0141 8.05889 21.3415 10.7322 18.6689 13.4054C18.6767 13.4289 18.6924 13.4523 18.7002 13.4758C22.459 13.4758 26.2256 13.4758 30 13.4758C30 14.4919 30 15.4768 30 16.493C26.2256 16.493 22.4668 16.493 18.6767 16.493C21.3806 19.1975 24.0453 21.863 26.7101 24.5284C25.9755 25.2475 25.2878 25.9276 24.5845 26.6232C21.951 23.9891 19.2785 21.3158 16.559 18.5878C16.5434 18.7363 16.5277 18.7989 16.5277 18.8614C16.5277 22.5039 16.5277 26.1464 16.5277 29.7968C16.5277 29.8671 16.5434 29.9297 16.5434 30C15.5275 30 14.5116 30 13.4957 30C13.5035 29.914 13.5113 29.828 13.5113 29.7421C13.5113 26.1386 13.5113 22.5352 13.5113 18.9317C13.5113 18.8536 13.4957 18.7676 13.4879 18.5956C10.745 21.3236 8.07242 23.9969 5.43892 26.6311C4.73561 25.9354 4.04011 25.2553 3.27429 24.505C5.93905 21.8395 8.6038 19.174 11.2764 16.5008C11.2217 16.5008 11.1435 16.5008 11.0654 16.5008C7.46288 16.5008 3.86038 16.5008 0.25788 16.5008C0.17192 16.5008 0.0859599 16.5164 0 16.5164C0 15.5003 0 14.4841 0 13.468Z"
              fill="#070707"
            />
          </svg> */}
          {data.text}
        </div>
        <div className="cs_moving_text">
          <svg
            width={30}
            height={30}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 13.468C0.0859599 13.4758 0.17192 13.4836 0.25788 13.4836C3.86038 13.4836 7.46288 13.4836 11.0732 13.4836C11.1513 13.4836 11.2295 13.4836 11.292 13.4836C8.61943 10.8103 5.94686 8.13705 3.2821 5.4716C4.04011 4.72121 4.73561 4.04117 5.44673 3.33768C8.08023 5.97186 10.7528 8.64513 13.4957 11.3887C13.4957 7.53517 13.4957 3.76759 13.4957 0C14.5116 0 15.4962 0 16.5043 0C16.5043 3.7754 16.5043 7.52736 16.5043 11.2715C19.1769 8.59823 21.8494 5.92496 24.4986 3.27514C25.2566 4.03335 25.9443 4.72121 26.6476 5.4247C24.0141 8.05889 21.3415 10.7322 18.6689 13.4054C18.6767 13.4289 18.6924 13.4523 18.7002 13.4758C22.459 13.4758 26.2256 13.4758 30 13.4758C30 14.4919 30 15.4768 30 16.493C26.2256 16.493 22.4668 16.493 18.6767 16.493C21.3806 19.1975 24.0453 21.863 26.7101 24.5284C25.9755 25.2475 25.2878 25.9276 24.5845 26.6232C21.951 23.9891 19.2785 21.3158 16.559 18.5878C16.5434 18.7363 16.5277 18.7989 16.5277 18.8614C16.5277 22.5039 16.5277 26.1464 16.5277 29.7968C16.5277 29.8671 16.5434 29.9297 16.5434 30C15.5275 30 14.5116 30 13.4957 30C13.5035 29.914 13.5113 29.828 13.5113 29.7421C13.5113 26.1386 13.5113 22.5352 13.5113 18.9317C13.5113 18.8536 13.4957 18.7676 13.4879 18.5956C10.745 21.3236 8.07242 23.9969 5.43892 26.6311C4.73561 25.9354 4.04011 25.2553 3.27429 24.505C5.93905 21.8395 8.6038 19.174 11.2764 16.5008C11.2217 16.5008 11.1435 16.5008 11.0654 16.5008C7.46288 16.5008 3.86038 16.5008 0.25788 16.5008C0.17192 16.5008 0.0859599 16.5164 0 16.5164C0 15.5003 0 14.4841 0 13.468Z"
              fill="#070707"
            />
          </svg>
          {data.text}
          {/* <svg
            width={30}
            height={30}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 13.468C0.0859599 13.4758 0.17192 13.4836 0.25788 13.4836C3.86038 13.4836 7.46288 13.4836 11.0732 13.4836C11.1513 13.4836 11.2295 13.4836 11.292 13.4836C8.61943 10.8103 5.94686 8.13705 3.2821 5.4716C4.04011 4.72121 4.73561 4.04117 5.44673 3.33768C8.08023 5.97186 10.7528 8.64513 13.4957 11.3887C13.4957 7.53517 13.4957 3.76759 13.4957 0C14.5116 0 15.4962 0 16.5043 0C16.5043 3.7754 16.5043 7.52736 16.5043 11.2715C19.1769 8.59823 21.8494 5.92496 24.4986 3.27514C25.2566 4.03335 25.9443 4.72121 26.6476 5.4247C24.0141 8.05889 21.3415 10.7322 18.6689 13.4054C18.6767 13.4289 18.6924 13.4523 18.7002 13.4758C22.459 13.4758 26.2256 13.4758 30 13.4758C30 14.4919 30 15.4768 30 16.493C26.2256 16.493 22.4668 16.493 18.6767 16.493C21.3806 19.1975 24.0453 21.863 26.7101 24.5284C25.9755 25.2475 25.2878 25.9276 24.5845 26.6232C21.951 23.9891 19.2785 21.3158 16.559 18.5878C16.5434 18.7363 16.5277 18.7989 16.5277 18.8614C16.5277 22.5039 16.5277 26.1464 16.5277 29.7968C16.5277 29.8671 16.5434 29.9297 16.5434 30C15.5275 30 14.5116 30 13.4957 30C13.5035 29.914 13.5113 29.828 13.5113 29.7421C13.5113 26.1386 13.5113 22.5352 13.5113 18.9317C13.5113 18.8536 13.4957 18.7676 13.4879 18.5956C10.745 21.3236 8.07242 23.9969 5.43892 26.6311C4.73561 25.9354 4.04011 25.2553 3.27429 24.505C5.93905 21.8395 8.6038 19.174 11.2764 16.5008C11.2217 16.5008 11.1435 16.5008 11.0654 16.5008C7.46288 16.5008 3.86038 16.5008 0.25788 16.5008C0.17192 16.5008 0.0859599 16.5164 0 16.5164C0 15.5003 0 14.4841 0 13.468Z"
              fill="#070707"
            />
          </svg>
          {data.text}
          <svg
            width={30}
            height={30}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 13.468C0.0859599 13.4758 0.17192 13.4836 0.25788 13.4836C3.86038 13.4836 7.46288 13.4836 11.0732 13.4836C11.1513 13.4836 11.2295 13.4836 11.292 13.4836C8.61943 10.8103 5.94686 8.13705 3.2821 5.4716C4.04011 4.72121 4.73561 4.04117 5.44673 3.33768C8.08023 5.97186 10.7528 8.64513 13.4957 11.3887C13.4957 7.53517 13.4957 3.76759 13.4957 0C14.5116 0 15.4962 0 16.5043 0C16.5043 3.7754 16.5043 7.52736 16.5043 11.2715C19.1769 8.59823 21.8494 5.92496 24.4986 3.27514C25.2566 4.03335 25.9443 4.72121 26.6476 5.4247C24.0141 8.05889 21.3415 10.7322 18.6689 13.4054C18.6767 13.4289 18.6924 13.4523 18.7002 13.4758C22.459 13.4758 26.2256 13.4758 30 13.4758C30 14.4919 30 15.4768 30 16.493C26.2256 16.493 22.4668 16.493 18.6767 16.493C21.3806 19.1975 24.0453 21.863 26.7101 24.5284C25.9755 25.2475 25.2878 25.9276 24.5845 26.6232C21.951 23.9891 19.2785 21.3158 16.559 18.5878C16.5434 18.7363 16.5277 18.7989 16.5277 18.8614C16.5277 22.5039 16.5277 26.1464 16.5277 29.7968C16.5277 29.8671 16.5434 29.9297 16.5434 30C15.5275 30 14.5116 30 13.4957 30C13.5035 29.914 13.5113 29.828 13.5113 29.7421C13.5113 26.1386 13.5113 22.5352 13.5113 18.9317C13.5113 18.8536 13.4957 18.7676 13.4879 18.5956C10.745 21.3236 8.07242 23.9969 5.43892 26.6311C4.73561 25.9354 4.04011 25.2553 3.27429 24.505C5.93905 21.8395 8.6038 19.174 11.2764 16.5008C11.2217 16.5008 11.1435 16.5008 11.0654 16.5008C7.46288 16.5008 3.86038 16.5008 0.25788 16.5008C0.17192 16.5008 0.0859599 16.5164 0 16.5164C0 15.5003 0 14.4841 0 13.468Z"
              fill="#070707"
            />
          </svg>
          {data.text}
          <svg
            width={30}
            height={30}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 13.468C0.0859599 13.4758 0.17192 13.4836 0.25788 13.4836C3.86038 13.4836 7.46288 13.4836 11.0732 13.4836C11.1513 13.4836 11.2295 13.4836 11.292 13.4836C8.61943 10.8103 5.94686 8.13705 3.2821 5.4716C4.04011 4.72121 4.73561 4.04117 5.44673 3.33768C8.08023 5.97186 10.7528 8.64513 13.4957 11.3887C13.4957 7.53517 13.4957 3.76759 13.4957 0C14.5116 0 15.4962 0 16.5043 0C16.5043 3.7754 16.5043 7.52736 16.5043 11.2715C19.1769 8.59823 21.8494 5.92496 24.4986 3.27514C25.2566 4.03335 25.9443 4.72121 26.6476 5.4247C24.0141 8.05889 21.3415 10.7322 18.6689 13.4054C18.6767 13.4289 18.6924 13.4523 18.7002 13.4758C22.459 13.4758 26.2256 13.4758 30 13.4758C30 14.4919 30 15.4768 30 16.493C26.2256 16.493 22.4668 16.493 18.6767 16.493C21.3806 19.1975 24.0453 21.863 26.7101 24.5284C25.9755 25.2475 25.2878 25.9276 24.5845 26.6232C21.951 23.9891 19.2785 21.3158 16.559 18.5878C16.5434 18.7363 16.5277 18.7989 16.5277 18.8614C16.5277 22.5039 16.5277 26.1464 16.5277 29.7968C16.5277 29.8671 16.5434 29.9297 16.5434 30C15.5275 30 14.5116 30 13.4957 30C13.5035 29.914 13.5113 29.828 13.5113 29.7421C13.5113 26.1386 13.5113 22.5352 13.5113 18.9317C13.5113 18.8536 13.4957 18.7676 13.4879 18.5956C10.745 21.3236 8.07242 23.9969 5.43892 26.6311C4.73561 25.9354 4.04011 25.2553 3.27429 24.505C5.93905 21.8395 8.6038 19.174 11.2764 16.5008C11.2217 16.5008 11.1435 16.5008 11.0654 16.5008C7.46288 16.5008 3.86038 16.5008 0.25788 16.5008C0.17192 16.5008 0.0859599 16.5164 0 16.5164C0 15.5003 0 14.4841 0 13.468Z"
              fill="#070707"
            />
          </svg>
          {data.text} */}
        </div>
      </div>
    </div>
  );
}
